exports.components = {
  "component---src-components-single-project-js": () => import("./../../../src/components/SingleProject.js" /* webpackChunkName: "component---src-components-single-project-js" */),
  "component---src-components-single-vacancy-js": () => import("./../../../src/components/SingleVacancy.js" /* webpackChunkName: "component---src-components-single-vacancy-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-geography-js": () => import("./../../../src/pages/geography.js" /* webpackChunkName: "component---src-pages-geography-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-page-js": () => import("./../../../src/pages/newsPage.js" /* webpackChunkName: "component---src-pages-news-page-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-vacancies-js": () => import("./../../../src/pages/vacancies.js" /* webpackChunkName: "component---src-pages-vacancies-js" */)
}

